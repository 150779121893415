import React, { useEffect, useRef, useState } from 'react';
import PageTitle from '../../../components/PageTitle';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import LoadingBar from 'react-top-loading-bar';
import { useMutation, useQuery } from 'react-query';
import Waste from '../../API/Waste';
import NewSubscription from './NewSubscription';
import EditSubscription from './EditSubscription';
import DeleteSubscription from './DeleteSubscription';

const Subscriptions = () => {

  const ref = useRef(null);
  const [state,setState] = useState([]);

  const {refetch} = useQuery('fetch-waste',Waste.fetchSubscriptions,{
    onSuccess:(x)=>{
        setState({subscriptions:x.data.subscriptions})
    },
    onSettled:()=>{
        ref.current.complete();
    },
    onError:(x)=>{
        console.log(x);
    }
  });

    useEffect(()=>{
        ref.current.continuousStart();
    },[]);


  return (
    <div>
        <LoadingBar ref={ref} color="green" progress={5}  />

        <PageTitle />
        <Row>
            <Card>
                <Card.Body>
                    <div>
                        <Button style={{float:'right'}} 
                        onClick={()=>setState({...state,showNew:true})}
                        >
                            New Subscription
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </Row>
        <Row>
               <Table>
                    <thead className="table-dark">
                    <tr>
                        <th scope="col">Frequency</th>
                        <th scope="col">Name</th>
                        <th scope="col">Amount</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody className="table-light">
                        {
                            (state?.subscriptions||[]).map((x,index)=>{
                                return <tr key={index}>
                                            <td>{x.frequency}</td>
                                            <td>{x.subscriptionName}</td>
                                            <td>USD {x.amount}</td>
                                            <td>
                                                <FeatherIcon 
                                                onClick={()=>{
                                                    setState({...state,selectedSub:x,showEdit:true})
                                                }}
                                                icon="edit-2" style={{cursor:'pointer',marginRight:20}}  />
                                                {/* <FeatherIcon 
                                                onClick={()=>{
                                                    setState({...state,showDelete:true,seletedId:x._id,name:x.subscriptionName})
                                                }}
                                                icon="trash" 
                                                style={{cursor:'pointer'}}  /> */}
                                            </td>
                                       </tr>
                            })
                        }
                    </tbody>
                </Table>
                <NewSubscription 
                refetch={refetch}
                show={state.showNew} onHide={()=>setState({...state,showNew:false})}
                />
                <EditSubscription
                refetch={refetch}
                show={state.showEdit} onHide={()=>setState({...state,showEdit:false})}
                {...state.selectedSub}
                />
                <DeleteSubscription
                show={state.showDelete}
                _id={state.seletedId}
                onClose={()=>setState({...state,showDelete:false})}
                refetch={refetch}
                name={state.name}
                />
        </Row>
    </div>
  )
}

export default Subscriptions;