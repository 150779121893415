import React from 'react';
import { Redirect } from 'react-router-dom';

import { isUserAuthenticated } from '../SweepAfrica/API/Cookies';

const Root = () => {
    const getRootUrl = () => {
        let url: string = 'landing';

        // check if user logged in or not and return url accordingly
        if (isUserAuthenticated() === false) {
            url = 'auth/login';
        } else {
            url = 'waste/zones';
        }
        return url;
    };

    const url = getRootUrl();

    return <Redirect to={`/${url}`} />;
};

export default Root;
