import React, { useState } from 'react'
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'react-bootstrap'
import { FormInput, VerticalForm } from '../../../../../components'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Spinner from '../../../../../components/Spinner';
import { useMutation } from 'react-query';
import Waste from '../../../../API/Waste';

const NewSubZone = (props) => {

    const [ state, setState] = useState({});

    const { mutate, error, isLoading } = useMutation(Waste.createSubZone,{
        onSuccess:()=>{
            document.location.reload();
        },
    })

    const schemaResolver = yupResolver(
        yup.object().shape({
            subZoneName: yup.string().required('Please enter subzone name'),
        })
    );

    const handleSubmit = (data)=>{
        const prepData = {...data,zone:props._id};
        mutate(prepData);
    };

  return (
    <Offcanvas show={props.show} onHide={props.onHide} placement="end">
        <OffcanvasHeader closeButton>New SubZone Zone</OffcanvasHeader>
            <OffcanvasBody>
                <VerticalForm
                onSubmit={handleSubmit}
                resolver={schemaResolver}
                >
                    <FormInput
                    type="text"
                    label='Zone Name'
                    name='subZoneName'
                    containerClass={'mb-3'}
                    />
                    {!isLoading? <Button
                    type="submit"
                    >
                        Create new subzone zone
                    </Button> : <Spinner/>}
                </VerticalForm>
        </OffcanvasBody>
    </Offcanvas>
  )
}

export default NewSubZone