import React from 'react'
import { Button, Modal, ModalFooter, Spinner } from 'react-bootstrap';
import { useMutation } from 'react-query';
import Waste from '../../API/Waste';

const DeleteSubscription = (props) => {

    const {mutate, isLoading} = useMutation(Waste.deleteSubscription,{
        onSettled:()=>{
            props.refetch();
            props.onClose();
        }
    })

  return (
    <Modal size="lg" centered show={props.show}>
        <Modal.Header closeButton onHide={props.onClose}>Deleting Subscription. <strong> {props.name} </strong></Modal.Header>
        <Modal.Body>
            <p>Are you sure you want to delete this subscription ?</p>
            <br></br>
            {isLoading && <Spinner/>}
        </Modal.Body>
        <ModalFooter>
            <Button disabled={isLoading}  onClick={()=>mutate(props._id)}>Yes Delete</Button>
            <Button disabled={isLoading}  onClick={props.onClose}>No Cancel</Button>
        </ModalFooter>
    </Modal>
  )
}

export default DeleteSubscription;