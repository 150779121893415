import React from 'react';

import Routes from './routes/Routes';

// Themes
import './assets/scss/Theme.scss';

const App = () => {
    return (
        <>
            <Routes></Routes>
        </>
    );
};

export default App;
