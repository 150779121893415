import React, { useState } from 'react'
import { Offcanvas ,OffcanvasHeader,OffcanvasBody, Button, Spinner, Alert} from 'react-bootstrap';
import { FormInput, VerticalForm } from '../../../../components';
import { useMutation, useQuery } from 'react-query';
import Waste from '../../../API/Waste';

const NewClientSub = (props) => {

    const [zones,setZones] = useState([]);
    const [subscriptions,setSubscriptions] = useState([]);


    useQuery('fetch-subzones-pre-client',Waste.fetchZones,{
        onSuccess:(x)=>{
            setZones(x.data.subzones);
        }
    });

    useQuery('fetch-subs-pre-client',Waste.fetchSubscriptions,{
        onSettled:(data)=>{
            console.log(data);
        },
        onError:(x)=>{
            console.log(x);
        },
        onSuccess:(x)=>{
            setSubscriptions(x.data?.subscriptions);
        }
      });

      const { mutate,isLoading,error } = useMutation(Waste.createClientSubscriptions,{
        onSuccess:()=>{
            props.refetch();
            props.onHide();
        }
      });

      const handlerSubmit =(x)=>{
            const data = {...x,coordinates:{latitude:Number(x.latitude),longitude:Number(x.longitude)}}
            mutate(data)
      }

  return (

    <Offcanvas show={props.show} onHide={props.onHide} placement="end">
         <OffcanvasHeader>New Client</OffcanvasHeader>
            <OffcanvasBody>
                {
                    error && <Alert>
                        {error.message}
                    </Alert>
                }
                <VerticalForm
                onSubmit={handlerSubmit}
                >
                    <FormInput
                    type="text"
                    name="phone"
                    containerClass='mb-3'
                    label="Enter client phone"
                    placeholder='eg, 263782714577'
                    />
                    <FormInput
                    type="text"
                    name="addressDirections"
                    containerClass='mb-3'
                    label="Address directions"
                    />
                     <FormInput
                    type="text"
                    name="latitude"
                    containerClass='mb-3'
                    label="latitude Position"
                    />
                     <FormInput
                    type="text"
                    name="longitude"
                    containerClass='mb-3'
                    label="Longitude Position"
                    />
                    <FormInput
                    type="text"
                    name="contactName"
                    containerClass='mb-3'
                    label="Contact Name"
                    />
                    <FormInput
                    type="select"
                    label="Select Zone"
                    name="subzone"
                    containerClass='mb-3'
                    >
                       { (zones||[]).map((z,index)=>{
                                        return <option key={index+"sub"} value={z._id}>{z.subZoneName}</option>
                                 })
                        }
                    </FormInput>
                    <FormInput
                    type='select'
                    label="Select Subscription for this client"
                    name="subscription"
                    containerClass={'mb-3'}
                    >
                            <option></option>
                        {
                            (subscriptions||[]).map((x)=>{
                                return <option value={x?._id}>{x.subscriptionName}(USD {x.amount})</option>
                            })
                        }
                    </FormInput>
                    <Button
                    type="submit"
                    >
                       {
                        isLoading ? <Spinner></Spinner>:'Create new client subscription'
                       }
                    </Button>
                </VerticalForm>
            </OffcanvasBody>
    </Offcanvas>
  )
};

export default NewClientSub;