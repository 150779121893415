import React, { useEffect, useRef, useState } from 'react';
import PageTitle from '../../../../components/PageTitle';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import LoadingBar from 'react-top-loading-bar';
import { useMutation, useQuery } from 'react-query';
import Waste from '../../../API/Waste';
import NewSchedules from './NewSchedules';
import EditSchedules from './EditSchedules';
import DeleteWasteSchedule from './DeleteWasteSchedule';

const WasteSchedules = () => {

  const ref = useRef(null);
  
  const [state,setState] = useState({});

  const { refetch, data } = useQuery('fetch-schedules',Waste.fetchSchedules,{
    onSettled:()=>{
        ref.current.complete();
    },
    onSuccess:(x)=>{
        setState({...state,schedules:x.data.schedules})
    }
  });

    useEffect(()=>{
        ref.current.continuousStart();
    },[]);


  return (
    <div>
        <LoadingBar ref={ref} color="green" progress={5}  />
        <PageTitle />
        <Row>
            <Card>
                <Card.Body>
                    <div>
                        <Button style={{float:'right'}} 
                        onClick={()=>setState({...state,showNew:true})}
                        >
                            Create new schedule
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </Row>
        <Row>
               <Table>
                    <thead className="table-dark">
                    <tr>
                        <th scope="col">Schedule Name</th>
                        <th scope="col">Subscription</th>
                        <th scope="col">Zone</th>
                        <th scope="col">Truck Plate</th>
                        <th scope="col">Truck Name</th>
                        <th scope="col">Truck Contact</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody className="table-light">
                        {
                            (state.schedules||[]).map((x,index)=>{
                                return <tr key={index}>
                                            <td>{x.scheduleName}</td>
                                            <td>{x.subscription?.subscriptionName} (${x.subscription?.amount})</td>
                                            <td>{x.zone?.zoneName}</td>
                                            <td>{x.sweepTruck?.truckNumberPlate}</td>
                                            <td>{x.sweepTruck?.truckDriverName}</td>
                                            <td>{x.sweepTruck?.truckDriverContact}</td>
                                            <td>
                                                <FeatherIcon 
                                                onClick={()=>{
                                                    setState({...state,selectedSub:x,showEdit:true,pickedDates:x.pickedDates})
                                                }}
                                                icon="edit-2" style={{cursor:'pointer',marginRight:20}}  />
                                                {/* <FeatherIcon 
                                                onClick={()=>{
                                                    setState({...state,showDelete:true,seletedId:x._id,name:x.scheduleName})
                                                }}
                                                icon="trash" 
                                                style={{cursor:'pointer'}}  /> */}
                                            </td>
                                       </tr>
                            })
                        }
                    </tbody>
                </Table>
                <NewSchedules
                refetch={refetch}
                show={state.showNew} 
                onHide={()=>setState({...state,showNew:false})}
                />
                <EditSchedules
                 refetch={refetch}
                 show={state.showEdit} onHide={()=>setState({...state,showEdit:false})}
                 {...state.selectedSub}
                />
                <DeleteWasteSchedule
                 show={state.showDelete}
                 _id={state.seletedId}
                 onClose={()=>setState({...state,showDelete:false})}
                 refetch={refetch}
                 name={state.name}
                />
        </Row>
    </div>
  )
}

export default WasteSchedules;