import React from 'react'
import { Modal } from 'react-bootstrap'
import GoogleMaps from '../../../../pages/maps/GoogleMaps'

const AddressModal = (props) => {

  return (
    <Modal size='lg' show={props.show} onHide={props.onClose} >
        <Modal.Header closeButton>
           <h3>Address Details</h3> 
        </Modal.Header>
        <Modal.Body>
            <p>{props.address}</p>
            <GoogleMaps
             coordinates={props.coordinates}
            />
        </Modal.Body>
    </Modal>
  )
}

export default AddressModal