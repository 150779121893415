import React, { useState } from 'react'
import { Offcanvas ,OffcanvasHeader,OffcanvasBody, Button, Spinner, Alert} from 'react-bootstrap';
import { FormInput, VerticalForm } from '../../../../components';
import { useMutation, useQuery } from 'react-query';
import Waste from '../../../API/Waste';

const EditClientSub = (props) => {

    const [zones,setZones] = useState([]);
    const [subscriptions,setSubscriptions] = useState([]);
    console.log(props);

    useQuery('fetch-subzones-pre-client-edit',Waste.fetchZones,{
        onSuccess:(x)=>{
            setZones(x.data.subzones);
        }
    });

    useQuery('fetch-subs-pre-client-edit',Waste.fetchSubscriptions,{
        onSettled:(data)=>{
            console.log(data);
        },
        onError:(x)=>{
            console.log(x);
        },
        onSuccess:(x)=>{
            setSubscriptions(x.data?.subscriptions);
        }
      });

      const { mutate,isLoading,error } = useMutation(Waste.editClientSubscriptions,{
        onSuccess:()=>{
            props.refetch();
            props.onHide();
        }
      });

      const handlerSubmit =(x)=>{
            const data = {...x,coordinates:{latitude:Number(x.latitude),longitude:Number(x.longitude)}}
            mutate({...data,_id:props._id});
      };

      const subzone = props?.subzone && props?.subzone[0]
      const sub = props?.subscription && props?.subscription[0]

  return (

    <Offcanvas show={props.showEdit} onHide={props.onHide} placement="end">
         <OffcanvasHeader>Edit Client</OffcanvasHeader>
            <OffcanvasBody>
                {
                    error && <Alert>
                        {error.message}
                    </Alert>
                }
                <VerticalForm
                onSubmit={handlerSubmit}
                >
                    <FormInput
                    type="textarea"
                    name="addressDirections"
                    containerClass='mb-3'
                    defaultValue={props.addressDirections}
                    label="Address directions"
                    />
                     <FormInput
                    type="text"
                    name="latitude"
                    defaultValue={props.coordinates?.latitude}
                    containerClass='mb-3'
                    label="latitude Position"
                    />
                     <FormInput
                    type="text"
                    name="longitude"
                    defaultValue={props.coordinates?.longitude}
                    containerClass='mb-3'
                    label="Longitude Position"
                    />
                    <FormInput
                    type="select"
                    label="Select Zone"
                    defaultValue={subzone?._id}
                    name="subzone"
                    containerClass='mb-3'
                    >
                        <option></option>
                       { (zones||[]).map((z,index)=>{
                                        return <option key={index+"sub"} value={z._id}>{z.subZoneName}</option>
                                 })
                        }
                    </FormInput>
                    <FormInput
                    type='select'
                    label="Select Subscription for this client"
                    name="subscription"
                    defaultValue={sub?._id}
                    containerClass={'mb-3'}
                    >
                            <option></option>
                        {
                            (subscriptions||[]).map((x)=>{
                                return <option value={x?._id}>{x.subscriptionName}(USD {x.amount})</option>
                            })
                        }
                    </FormInput>
                    <Button
                    type="submit"
                    >
                       {
                        isLoading ? <Spinner></Spinner>:'update client subscription'
                       }
                    </Button>
                </VerticalForm>
            </OffcanvasBody>
    </Offcanvas>
  )
};

export default EditClientSub;