import React, { useState } from 'react'
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'react-bootstrap'
import { FormInput, VerticalForm } from '../../../../components'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Spinner from '../../../../components/Spinner';
import { useMutation } from 'react-query';
import Waste from '../../../API/Waste';
import axios from 'axios';

const NewSweepTruck = (props) => {

    const [ state, setState] = useState({});

    const { mutate, error, isLoading } = useMutation(Waste.createNewSweepTruck,{
        onSuccess:()=>{
            setState({});
            props.refetch();
            props.onHide();
        },
    })

    const schemaResolver = yupResolver(
        yup.object().shape({
            truckType: yup.string().required('Please select truck type'),
            truckNumberPlate: yup.string().required('Truck number plate is required'),
            truckDriverName: yup.string().required('Truck driver is required'),
            truckDriverContact:yup.string().required("Truck driver contact details are required")
        })
    );

    
  return (
    <Offcanvas show={props.show} onHide={props.onHide} placement="end">
        <OffcanvasHeader>New Sweeptruck</OffcanvasHeader>
            <OffcanvasBody>
                <VerticalForm
                onSubmit={mutate}
                resolver={schemaResolver}
                >
                    <FormInput
                    type="text"
                    label='Truck number plate'
                    name='truckNumberPlate'
                    containerClass={'mb-3'}
                    />
                    <FormInput
                    type="select"
                    label='Truck type'
                    name='truckType'
                    containerClass={'mb-3'}
                    >
                        <option value="1tonne">
                            1 Tonne
                        </option>
                        <option value="2tonne">
                            2 Tonne
                        </option>
                        <option value="3tonne">
                            3 Tonne
                        </option>
                        <option value="4tonne">
                            4 Tonne
                        </option>
                        <option value="5tonne">
                            5 Tonne
                        </option>
                        <option value="10tonne">
                            10 Tonne
                        </option>
                        <option value="15tonne">
                            15 Tonne
                        </option>
                        <option value="30tonne">
                            15 Tonne
                        </option>
                    </FormInput>
                    <FormInput
                    type="text"
                    label='Truck driver name'
                    name='truckDriverName'
                    containerClass={'mb-3'}
                    />
                    <FormInput
                    type="text"
                    label='Truck Driver Contact'
                    name='truckDriverContact'
                    containerClass={'mb-3'}
                    />
                    <FormInput
                    type="text"
                    label='Truck Driver Contact (Other)'
                    name='secondaryDriverContactDetails'
                    containerClass={'mb-3'}
                    />
                    <FormInput
                    type="text"
                    label='Truck Owner Name'
                    name='truckOwnerName'
                    containerClass={'mb-3'}
                    />
                    <FormInput
                    type="text"
                    label='Truck Owner Contacts'
                    name='truckOwnerContact'
                    containerClass={'mb-3'}
                    />
                    {!isLoading? <Button
                    type="submit"
                    >
                        Create new sweep truck
                    </Button> : <Spinner/>}
                </VerticalForm>
        </OffcanvasBody>
    </Offcanvas>
  )
}

export default NewSweepTruck;