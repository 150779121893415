import ApiClient from "./Client";
import { getLoggedInUser } from "./Cookies";

const version = '/api/v1';

const requestParams = {
  headers: {
    'x-access-token': getLoggedInUser()?.token
  }
};

class AuthServices {

    loginAmin = (data) => ApiClient().post(`${version}/adminauth`, data ,requestParams);

}


export default new AuthServices();
