import React, { useState } from 'react'
import { Button, Row, Col, Offcanvas, OffcanvasBody, OffcanvasHeader, Form } from 'react-bootstrap'
import { FormInput, VerticalForm } from '../../../components'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Spinner from '../../../components/Spinner';
import { useMutation } from 'react-query';
import Waste from '../../API/Waste';
import axios from 'axios';
import FeatherIcon from 'feather-icons-react';

const NewSubscription = (props) => {

    const [ state, setState] = useState({});

    const { mutate, error, isLoading } = useMutation(Waste.createNewSubscription,{
        onSuccess:()=>{
            setState({});
            props.refetch();
            props.onHide();
        },
    })

    const schemaResolver = yupResolver(
        yup.object().shape({
            subscriptionName: yup.string().required('Please enter subscription name'),
            frequency: yup.string().required('Frequency is required'),
            subscriptionDescription: yup.string().required('Please enter description'),
            amount:yup.string().required("Please enter subscription amount")
        })
    );


    const addItem =()=>{
        const dates = state?.subscriptionItems ||[];
        dates.push('');
        setState({...state,subscriptionItems:dates})
    };

    const onSubmit=(data)=>{
        mutate({...state,...data});
    };
    
  return (
    <Offcanvas show={props.show} onHide={props.onHide} placement="end">
        <OffcanvasHeader>New Subscription</OffcanvasHeader>
            <OffcanvasBody>
                <VerticalForm
                onSubmit={onSubmit}
                resolver={schemaResolver}
                >
                    <FormInput
                    type="text"
                    label='Subscription Name'
                    name='subscriptionName'
                    containerClass={'mb-3'}
                    />
                    <FormInput
                    type="select"
                    label='Subscription Frequency'
                    name='frequency'
                    containerClass={'mb-3'}
                    >
                        <option value="onetime">
                            One Time
                        </option>
                        <option value="biweekly">
                            Bi Weekly
                        </option>
                        <option value="monthly">
                            Monthly
                        </option>
                        <option value="yearly">
                            Yearly
                        </option>
                    </FormInput>
                    <Row>
                        <Col>
                        <Form.Label>Subscription Items</Form.Label>
                        </Col>
                        <Col>
                        <Button onClick={addItem} style={{float:'right'}} size='sm' ><FeatherIcon icon='plus' /></Button>
                        </Col>
                    </Row>
                    <br></br>
                    {   
                (state.subscriptionItems||[]).map((x,index)=>{
                    return <Row key={index}>
                                <Col lg="10">
                                    <FormInput
                                    type="text"
                                    label={'Item ' + (index+1)}
                                    containerClass={'mb-3'}
                                    onChange={(x)=>{
                                        const dates = state?.subscriptionItems ||[];
                                        dates[index] = x.target.value;
                                        dates.splice(index,1,dates[index]);
                                        setState({...state,subscriptionItems:dates})
                                    }}
                                    value={x}
                                    />
                                </Col>
                                <Col>
                                 <FeatherIcon  
                                 onClick={()=>{
                                    const dates = state?.subscriptionItems ||[];
                                    dates.splice(index,1);
                                    setState({...state,subscriptionItems:dates})
                                 }}
                                 style={{cursor:'pointer'}} icon='trash' />
                                </Col>
                          </Row>
                })
            }
                    <FormInput
                    type="textarea"
                    label='Subscription Description'
                    name='subscriptionDescription'
                    containerClass={'mb-3'}
                    />
                    <FormInput
                    type="number"
                    label='Subscription Amount'
                    name='amount'
                    containerClass={'mb-3'}
                    />
                    {!isLoading? <Button
                    type="submit"
                    >
                        Create new subscription
                    </Button> : <Spinner/>}
                </VerticalForm>
        </OffcanvasBody>
    </Offcanvas>
  )
}

export default NewSubscription