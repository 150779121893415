import React, { useState } from 'react'
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'react-bootstrap'
import { FormInput, VerticalForm } from '../../../../components'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Spinner from '../../../../components/Spinner';
import { useMutation } from 'react-query';
import Waste from '../../../API/Waste';

const NewZone = (props) => {

    const [ state, setState] = useState({});

    const { mutate, error, isLoading } = useMutation(Waste.createZone,{
        onSuccess:()=>{
            setState({});
            props.refetch();
            props.onHide();
        },
    })

    const schemaResolver = yupResolver(
        yup.object().shape({
            zoneName: yup.string().required('Please enter zone name'),
        })
    );

  return (
    <Offcanvas show={props.show} onHide={props.onHide} placement="end">
        <OffcanvasHeader>New Zone</OffcanvasHeader>
            <OffcanvasBody>
                <VerticalForm
                onSubmit={mutate}
                resolver={schemaResolver}
                >
                    <FormInput
                    type="text"
                    label='Zone Name'
                    name='zoneName'
                    containerClass={'mb-3'}
                    />
                    {!isLoading? <Button
                    type="submit"
                    >
                        Create new zone
                    </Button> : <Spinner/>}
                </VerticalForm>
        </OffcanvasBody>
    </Offcanvas>
  )
}

export default NewZone