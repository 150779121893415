import ApiClient from "./Client";
import { getLoggedInUser } from "./Cookies";

const version = '/api/v1';

const requestParams = {
  headers: {
    'x-access-token': getLoggedInUser()?.token
  }
};

class WasteServices {

  createNewSubscription = (data) => ApiClient().post(`${version}/subscriptions`, data ,requestParams);

  fetchSubscriptions = () => ApiClient().get(`${version}/subscriptions`, requestParams);

  updateSubscriptions = (data) => ApiClient().put(`${version}/subscriptions/${data._id}`, data, requestParams);

  deleteSubscription = (id) => ApiClient().delete(`${version}/subscriptions/${id}`, requestParams);

  fetchSweepTrucks = () => ApiClient().get(`${version}/sweeptrucks`, requestParams);

  createNewSweepTruck = (data)=>ApiClient().post(`${version}/sweeptrucks`,data,requestParams);

  updateSweepTruck = (data) => ApiClient().put(`${version}/sweeptrucks/${data._id}`,data,requestParams);

  deleteSweepTruck = (data) => ApiClient().delete(`${version}/sweeptrucks/${data}`,requestParams);

  fetchSchedules =()=> ApiClient().get(`${version}/schedules`, requestParams);

  editSchedules =(data)=> ApiClient().put(`${version}/schedules/${data._id}`,data, requestParams);

  deleteShedule =(id)=> ApiClient().delete(`${version}/schedules/${id}`,requestParams);

  createSchedule =(data)=> ApiClient().post(`${version}/schedules`,data,requestParams);

  fetchZones =()=>ApiClient().get(`${version}/zones`,requestParams);

  deleteZone =(id)=>ApiClient().delete(`${version}/zones/${id}`,requestParams);

  editZone =(data)=> ApiClient().put(`${version}/zones/${data._id}`,data,requestParams);

  createZone =(data)=> ApiClient().post(`${version}/zones`,data,requestParams);

  fetchSubZones =(id)=>ApiClient().get(`${version}/subzones/${id}`,requestParams);

  createSubZone=(data)=>ApiClient().post(`${version}/subzones`,data,requestParams);

  editSubZone=(data)=>ApiClient().put(`${version}/subzones/${data._id}`,data,requestParams);

  deleteSubZone=(id)=>ApiClient().delete(`${version}/subzones/${id}`,requestParams);

  fetchClientSubscriptions =(id)=>ApiClient().get(`${version}/clientsubscriptions`,requestParams);

  createClientSubscriptions =(data)=>ApiClient().post(`${version}/clientsubscriptions`,data,requestParams);

  editClientSubscriptions =(data)=>ApiClient().put(`${version}/clientsubscriptions/${data._id}`,data,requestParams);

}


export default new WasteServices();
