import React, { useEffect, useRef, useState } from 'react';
import PageTitle from '../../../../components/PageTitle';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import LoadingBar from 'react-top-loading-bar';
import { useMutation, useQuery } from 'react-query';
import Waste from '../../../API/Waste';
import NewSweepTruck from './NewSweepTruck';
import EditSweepTruck from './EditSweepTruck';
import DeleteSweepTruck from './DeleteSweepTruck';

const SweepTrucks = () => {

  const ref = useRef(null);
  const [state,setState] = useState([]);


  const {refetch, data } = useQuery('fetch-sweeptrucks',Waste.fetchSweepTrucks,{
    onSettled:()=>{
        ref.current.complete();
        console.log(data);
    },
    onError:(x)=>{
        console.log(x);
    },
    onSuccess:(x)=>{
        setState({sweeptrucks:x.data.sweeptrucks})
    }
  });

    useEffect(()=>{
        ref.current.continuousStart();
    },[]);


  return (
    <div>
        <LoadingBar ref={ref} color="green" progress={5}  />
        <PageTitle />
        <Row>
            <Card>
                <Card.Body>
                    <div>
                        <Button style={{float:'right'}} 
                        onClick={()=>setState({...state,showNew:true})}
                        >
                            New Sweep Truck
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </Row>
        <Row>
               <Table>
                    <thead className="table-dark">
                    <tr>
                        <th scope="col">Truck Plate</th>
                        <th scope="col">Type</th>
                        <th scope="col">D-Name</th>
                        <th scope="col">D-Contact</th>
                        <th scope="col">D-Contact-2</th>
                        <th scope="col">O-Name</th>
                        <th scope="col">O-Contact</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody className="table-light">
                        {
                            (state.sweeptrucks||[]).map((x,index)=>{
                                return <tr key={index}>
                                            <td>{x.truckNumberPlate}</td>
                                            <td>{x.truckType}</td>
                                            <td>{x.truckDriverName}</td>
                                            <td>{x.truckDriverContact}</td>
                                            <td>{x.secondaryDriverContactDetails}</td>
                                            <td>{x.truckOwnerName}</td>
                                            <td>{x.truckOwnerContact}</td>
                                            <td>
                                                <FeatherIcon 
                                                onClick={()=>{
                                                    setState({...state,selectedSub:x,showEdit:true})
                                                }}
                                                icon="edit-2" style={{cursor:'pointer',marginRight:20}}  />
                                                <FeatherIcon 
                                                onClick={()=>{
                                                    setState({...state,showDelete:true,seletedId:x._id,name:x.truckNumberPlate})
                                                }}
                                                icon="trash" 
                                                style={{cursor:'pointer'}}  />
                                            </td>
                                       </tr>
                            })
                        }
                    </tbody>
                </Table>
                <NewSweepTruck 
                refetch={refetch}
                show={state.showNew} onHide={()=>setState({...state,showNew:false})}
                />
                <EditSweepTruck
                refetch={refetch}
                show={state.showEdit} onHide={()=>setState({...state,showEdit:false})}
                {...state.selectedSub}
                />
                <DeleteSweepTruck
                show={state.showDelete}
                _id={state.seletedId}
                onClose={()=>setState({...state,showDelete:false})}
                refetch={refetch}
                name={state.name}
                />
        </Row>
    </div>
  )
}

export default SweepTrucks;