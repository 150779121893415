import React, { useEffect, useState } from 'react';
import { FormInput, VerticalForm } from '../../../../components';
import { Offcanvas, OffcanvasBody, OffcanvasHeader, Form, Button, Row, Col} from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import Waste from '../../../API/Waste';
import { useMutation, useQuery } from 'react-query';
import Spinner from '../../../../components/Spinner';
import { months } from './months';

const EditSchedules = (props) => {
    const [state,setState] = useState({});
    const [sweepTrucks,setSweepTrucks] = useState([])
    const [multiSelections, setMultiSelections] = useState([]);
    const [options,setOptions] = useState([]);
    const [subscriptions,setSubscriptions] = useState([]);

    const addDate =()=>{
        const dates = state?.pickupDates ||[];
        dates.push('23-23-23');
        setState({...state,pickupDates:dates})
    };

    useEffect(()=>{
        setState(props);
    },[props._id])

    const { refetch, data, isLoading:loading } = useQuery('fetch-sweeptrucks' ,Waste.fetchSweepTrucks,{
        onSuccess:(x)=>{
            setSweepTrucks(x.data.sweeptrucks)
        }
      });

    useQuery('fetch-subs-pre',Waste.fetchSubscriptions,{
        onSettled:()=>{
            console.log(data);
        },
        onError:(x)=>{
            console.log(x);
        },
        onSuccess:(x)=>{
            setSubscriptions(x.data?.subscriptions);
        }
    });

    useQuery('fetch-prezones-edit',Waste.fetchZones,{
        onSettled:()=>{
            console.log(data);
        },
        onError:(x)=>{
            console.log(x);
        },
        onSuccess:(x)=>{
            setOptions(x.data?.zones);
        }
      });

      const handleChange =(e)=>{
        setState({...state,[e.target.name]:e.target.value});
      }

      const { mutate, isLoading} = useMutation(Waste.editSchedules,{
        onSuccess:()=>{
            props.refetch();
            props.onHide();
        }
      });

      const onSubmit=()=>{
        mutate(state);
      }

      const sortDate =(data)=>{
            return new Date(data).toISOString().substring(0,10);
      };
      
  return (
    <Offcanvas show={props.show} onHide={props.onHide} placement="end">
    <OffcanvasHeader>New Schedule</OffcanvasHeader>
        <OffcanvasBody>
            <FormInput
            type="text"
            label='Schedule Name'
            onChange={handleChange}
            name='scheduleName'
            defaultValue={props.scheduleName}
            containerClass={'mb-3'}
            />
            <Row>
                <Col>
                <Form.Label>Schedule Dates</Form.Label>
                </Col>
                <Col>
                <Button onClick={addDate} style={{float:'right'}} size='sm' ><FeatherIcon icon='plus' /></Button>
                </Col>
            </Row>
            <br></br>
            {   
        (state?.pickupDates||props.pickupDates||[]).map((x,index)=>{
            return <Row key={index}>
                        <Col lg="10">
                            <FormInput
                            type="date"
                            label={'Schedule ' + (index+1)}
                            containerClass={'mb-3'}
                            defaultValue={x}
                            onChange={(x)=>{
                                const dates = state?.pickupDates ||[];
                                dates[index] = x.target.value;
                                dates.splice(index,1,dates[index]);
                                setState({...state,pickupDates:dates})
                            }}
                            value={x}
                            />
                        </Col>
                        <Col>
                            <FeatherIcon  
                            onClick={()=>{
                            const dates = state?.pickupDates ||[];
                            dates.splice(index,1);
                            setState({...state,pickupDates:dates})
                            }}
                            style={{cursor:'pointer'}} icon='trash' />
                        </Col>
                    </Row>
        })
            }
            <FormInput
            type='select'
            label="Select Sweep Trucks"
            name="sweepTruck"
            loading={isLoading}
            defaultValue={props.sweepTruck?._id}
            onChange={handleChange}
            containerClass={'mb-3'}
            >
                    <option></option>
                {
                    (sweepTrucks||[]).map((x)=>{
                        return <option value={x?._id}>{x?.truckNumberPlate}({x?.truckDriverName})</option>
                    })
                }
            </FormInput>
            <FormInput
            type='select'
            label="Select Subscription for this schedule"
            name="subscription"
            defaultValue={props.subscription?._id}
            onChange={handleChange}
            containerClass={'mb-3'}
            >
                    <option></option>
                {
                    (subscriptions||[]).map((x)=>{
                        return <option value={x?._id}>{x.subscriptionName}(USD {x.amount})</option>
                    })
                }
            </FormInput>
            <FormInput
            type='select'
            label="Select Zone for this schedule"
            name="zone"
            defaultValue={props.zone?._id}
            onChange={handleChange}
            containerClass={'mb-3'}
            >
                    <option></option>
                {
                    (options||[]).map((x)=>{
                        return <option value={x?._id}>{x.zoneName}</option>
                    })
                }
            </FormInput>
            <FormInput
            type='select'
            label="Select the month for this schedule"
            name="month"
            defaultValue={props.month}
            onChange={handleChange}
            containerClass={'mb-3'}
            >
                    <option></option>
                {
                    (months||[]).map((x,index)=>{
                        return <option value={index.toString()}>{x}</option>
                    })
                }
            </FormInput>
            {!isLoading ? <Button
            onClick={onSubmit}
            >
                Update Schedule
            </Button> : <Spinner/> }
        </OffcanvasBody>
    </Offcanvas>
  )
};

export default EditSchedules;