import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Row, Table,Offcanvas, OffcanvasBody, OffcanvasHeader } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import LoadingBar from 'react-top-loading-bar';
import { useMutation, useQuery } from 'react-query';
import Waste from '../../../../API/Waste';
import Spinner from '../../../../../components/Spinner';
import NewSubZone from './NewSubZone';
import EditSubZone from './EditSubZone';
import DeleteSubZone from './DeleteSubZone';

const SubZones = (props) => {

  const [state,setState] = useState({});

    useEffect(()=>{
        setState({subzones:props.subzones});
    },[props._id]);

  return (
    <Offcanvas style={{width:'100%',backgroundColor:'#f8f9fa'}} show={props.show} onHide={props.onHide} placement="end">
    <OffcanvasHeader closeButton>{props.zoneName} Subzones</OffcanvasHeader>
        <OffcanvasBody>
        <Row>
        <Col lg="2">
            </Col>
            <Col lg="8">
            <Card>
                <Card.Body>
                    <div>
                        <Button style={{float:'right'}} 
                        onClick={()=>setState({...state,showNew:true})}
                        >
                            Create Subzone for region
                        </Button>
                    </div>
                </Card.Body>
            </Card>
            </Col>
            <Col lg="2">
            </Col>
        </Row>
        <Row>
            <Col lg="2">
            </Col>
            <Col lg="8">
            <Table>
                <thead className="table-dark" style={{backgroundColor:'#000',padding:20}}>
                    <th scope="col">SubZone Name</th>
                    <th scope="col"></th>
                </thead>
                <tbody className="table-light">
                    {
                        (state.subzones||[]).map((x,index)=>{
                            return <tr key={index}>
                                    <td>{x.subZoneName}</td>
                                        <td>
                                            <FeatherIcon 
                                            onClick={()=>{
                                                setState({...state,selectedSub:x,showEdit:true})
                                            }}
                                            icon="edit-2" style={{cursor:'pointer',marginRight:20}}  />
                                            <FeatherIcon 
                                            onClick={()=>{
                                                setState({...state,showDelete:true,seletedId:x._id,name:x.subZoneName})
                                            }}
                                            icon="trash" 
                                            style={{cursor:'pointer'}}  />
                                        </td>
                                  </tr>
                        })
                    }
                </tbody>
            </Table>
            </Col>
            <Col lg="2">
            </Col>
            <NewSubZone
            _id={props._id}
            refetch={props.refetch}
            show={state.showNew} onHide={()=>setState({...state,showNew:false})}
            />
            <EditSubZone
            show={state.showEdit} onHide={()=>setState({...state,showEdit:false})}
            {...state.selectedSub}
            />
            <DeleteSubZone
            show={state.showDelete}
            _id={state.seletedId}
            onClose={()=>setState({...state,showDelete:false})}
            name={state.name}
            />
        </Row>
        
        </OffcanvasBody>
    </Offcanvas>
  )
}

export default SubZones