import React, { useState } from 'react'
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'react-bootstrap'
import { FormInput, VerticalForm } from '../../../../components'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Spinner from '../../../../components/Spinner';
import { useMutation } from 'react-query';
import Waste from '../../../API/Waste';

const EditZone = (props) => {

    const [ state, setState] = useState({});

    const { mutate, error, isLoading } = useMutation(Waste.editZone,{
        onSuccess:()=>{
            setState({});
            props.refetch();
            props.onHide();
        },
    });

    const schemaResolver = yupResolver(
        yup.object().shape({
            zoneName: yup.string().required('Please enter zone name'),
        })
    );

    const updateZone =(data)=>{
        mutate({_id:props._id,...data})
    };

  return (
    <Offcanvas show={props.show} onHide={props.onHide} placement="end">
        <OffcanvasHeader>Edit Zone</OffcanvasHeader>
            <OffcanvasBody>
                <VerticalForm
                onSubmit={updateZone}
                resolver={schemaResolver}
                >
                    <FormInput
                    type="text"
                    label='Zone Name'
                    defaultValue={props.zoneName}
                    name='zoneName'
                    containerClass={'mb-3'}
                    />
                    {!isLoading? <Button
                    type="submit"
                    >
                        update zone
                    </Button> : <Spinner/>}
                </VerticalForm>
        </OffcanvasBody>
    </Offcanvas>
  )
}

export default EditZone