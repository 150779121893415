import jwtDecode from 'jwt-decode';
const AUTH_SESSION_KEY = 'fingenieadminuser';

/**
 * Sets the default authorization
 * @param {*} token
 */

export const  getLoggedInUser = () => {
        return getUserFromCookie();
    };

 export const isUserAuthenticated = () => {
        const user = getLoggedInUser();

        if (!user) {
            return false;
        }
        const decoded  = jwtDecode(user.token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            return false;
        } else {
            return true;
        }
    };

 export const getDecodedUser =()=>{
        return isUserAuthenticated() && jwtDecode(getLoggedInUser().token);
    }


const getUserFromCookie = () => {
    const user = sessionStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

export const  setLoggedInUser = (session) => {
        if (session) sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
        else {
            sessionStorage.removeItem(AUTH_SESSION_KEY);
        }
    };

