import React, { useEffect, useRef, useState } from 'react'
import PageTitle from '../../../../components/PageTitle';
import { Button, Card, Row, Table } from 'react-bootstrap';
import { useQuery } from 'react-query';
import Auth from '../../../API/Auth';
import Waste from '../../../API/Waste';
import LoadingBar from 'react-top-loading-bar';
import FeatherIcon from 'feather-icons-react';
import AddressModal from './AddressModal';
import NewClientSub from './NewClientSub';
import EditClientSub from './EditClientSub';

const ClientSubsTable = () => {

    const [state,setState] = useState({});

    const ref = useRef(null);

    const {isLoading,refetch} = useQuery('client-subs',Waste.fetchClientSubscriptions,{
        onSuccess:(x)=>{
            setState({clientsubscriptions:x.data.clientplans})
        },
        onSettled:()=>{
            ref.current.complete();
        }
    });

    useEffect(()=>{
        ref.current.continuousStart();
    },[]);

  return (
    <div>
        <LoadingBar color="green" ref={ref} progress={5}/>
        <PageTitle />
        <Row>
            <Card>
                <Card.Body>
                    <Button style={{float:'right'}}
                    onClick={()=>setState({...state,showNew:true})}
                    >
                        New client subscription
                    </Button>
                </Card.Body>
            </Card>
        </Row>
        <Row>
            <Card>
                <Card.Body>
                        <Table>
                            <thead className='table-dark'>
                                    <tr>
                                        <th>
                                           Plan
                                        </th>
                                        <th>
                                           Whatsapp Phone
                                        </th>
                                        <th>
                                            Zone
                                        </th>
                                        <th>
                                            Suburb
                                        </th>
                                        <th>
                                            Address
                                        </th>
                                    </tr>
                            </thead>
                            <tbody className='table-light'>
                                {
                                    (state?.clientsubscriptions||[]).map((x,index)=>{
                                        return <tr key={index}>
                                                    <td>
                                                        {x?.subscription[0]?.subscriptionName} ${x?.subscription[0]?.amount}
                                                    </td>
                                                    <td>
                                                        {x?.client[0]?.phone}
                                                    </td> 
                                                    <td>
                                                        {x?.subzone[0]?.zone[0].zoneName}
                                                    </td>
                                                    <td>
                                                        {x?.subzone[0]?.subZoneName}
                                                    </td>
                                                    <td>
                                                        <FeatherIcon 
                                                        onClick={()=>setState({...state,showAddress:true,
                                                            coordinates:x.coordinates,
                                                            address:x?.addressDirections})}
                                                        icon="map" style={{cursor:'pointer',marginRight:10}}/>
                                                         <FeatherIcon 
                                                        onClick={()=>setState({...state,showEdit:true,selected:x})}
                                                        icon="edit-2" style={{cursor:'pointer'}}/>
                                                    </td>
                                              </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                </Card.Body>
            </Card>
        </Row>
        <AddressModal
        show={state.showAddress}
        onClose={()=>setState({...state,showAddress:false})}
        address={state.address}
        coordinates={state.coordinates}
        />
        <NewClientSub
        show={state.showNew}
        refetch={refetch}
        onHide={()=>setState({...state,showNew:false})}
        />
        <EditClientSub
         showEdit={state.showEdit}
         refetch={refetch}
         onHide={()=>setState({...state,showEdit:false})}
         {...state.selected}
        />
    </div>
  )
}

export default ClientSubsTable;